<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">Arab Investment Club</h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t("register.Email_veryficaition") }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t("register.Email_veryfication_send") }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-forgot-password-form mt-2">
            <!-- email -->
            <!-- <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- submit button -->
            <b-button variant="primary" block to="#" @click="validationForm">
              {{ $t("register.resend_Email_Link") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t("register.Back_to_login") }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    VuexyLogo,
    ToastificationContent,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // validation
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      getCurrnetVerifyEmail: "profile/getCurrentVerifyEmail",
    }),
  },
  created() {
    if (localStorage.getItem("language") == "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.body.style.fontFamily = "Tajawal";
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      // document.body.style.fontFamily = "Montserrat,Helvetica,Arial, serif";
      document.body.style.fontFamily = "nftbplus,sans-serif";
    }
  },
  methods: {
    ...mapMutations({
      SET_CURRENT_VERIFY_EMAIL: "profile/SET_CURRENT_VERIFY_EMAIL",
    }),
    ...mapActions({
      resendEmailToken: "auth/resendEmailToken",
    }),
    validationForm() {
      const formData = new FormData();
      console.log(this.getCurrnetVerifyEmail);
      formData.append("email", this.getCurrnetVerifyEmail);
      this.resendEmailToken(formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    setLocale(value) {
      // alert(this.$i18n.locale);
      this.$i18n.locale = value;
      // store.commit("appConfig/TOGGLE_RTL", true);
      if (value === "ar") {
        store.commit("appConfig/TOGGLE_RTL", true);
        // store.dispatch("local/setLanguage", "ar");
        // store.commit("locale/changeLang");
        this.changeLang();
      }
      if (value === "en") {
        store.commit("appConfig/TOGGLE_RTL", false);
        this.changeLang();
      }
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
